import { CheckOutlined } from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import CartContext from "../../contexts/CartContext";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import MainContext from "../../contexts/MainContext";
import AuthContext from "../../contexts/AuthContext";
import { formatDateToW100 } from "../../utils/functions";
import { useTranslation } from "react-i18next";
import PriceContext from "../../contexts/PriceContext";

const ValiderCommandeBtn = {
  backgroundColor: "#6ab04c",
  width: "100%",
  padding: "12px",
  border: "none",
  color: "white",
  fontSize: "1.4rem",
  fontWeight: "bold",
  cursor: "pointer",
};

const ValiderCommande = ({ commande, user, selectedRepObject = null }) => {
  const [lignesList, setLignesList] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { accessTokenJwt, isLoadingAccessToken } = useContext(AuthContext);
  const { clearCart } = useContext(CartContext);
  const { setCurrent } = useContext(MainContext);
  const { idDevise, categorieTarifaire } = useContext(PriceContext);

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (commande && commande.client) {
      createLignesDocument(commande);
    }
  }, [commande]);

  /**Créer les lignes du bon de commande */
  const createLignesDocument = (commande) => {
    let lignesList = [];
    commande?.cart?.forEach((product) => {
      lignesList.push({
        __type: "LigneArticle:http://www.proconsult.lu/WebServices100",
        TypeDocument: 1,
        // IdGamme1: 0,
        Quantite: product.qty,
        RefArticle: product.product.Reference,
        Intitule: product.product.Intitule,
        EstValorise: 1,
        PrixUnitaire:
          idDevise !== 0
            ? product?.product?.CategoriesTarifaire?.[categorieTarifaire]?.PrixDevise
            : product?.product?.CategoriesTarifaire?.[categorieTarifaire]?.PrixVente,
        PrixUnitaireDevise:
          idDevise !== 0
            ? product?.product?.CategoriesTarifaire?.[categorieTarifaire]?.PrixDevise
            : 0,
        // PrixUnitaireBC: product.product.PrixVente,
        // PrixUnitaireTTC: product.product.PrixVente,
        // MontantHT: product.product.PrixVente,
        // MontantTTC: product.product.PrixVente,
        CodeTaxe1: commande?.client[0]?.IdCategorieComptable === 1 ? "C04" : null,
        Taux1: commande?.client[0]?.IdCategorieComptable === 1 ? 20.0 : 0,
        RemiseType1: commande?.client[0]?.TauxRemise !== 0 ? 1 : 0,
        RemiseValeur1: commande?.client[0]?.TauxRemise !== 0 ? commande?.client[0]?.TauxRemise : 0,
        ...product,
      });
    });
    setLignesList(lignesList);
  };

  const sendCommand = async (commande, lignesList, accessTokenJwt) => {
    const id = new Date().getTime();
    const url = `${process.env.REACT_APP_API_URL}/documents/bon-commande/create`;
    const headers = {
      Authorization: `Bearer ${accessTokenJwt}`,
    };
    const body = {
      document: {
        __type: "DocumentVente:http://www.proconsult.lu/WebServices100",
        TypeDocument: 1,
        Domaine: 0,
        Date: formatDateToW100(),
        Reference: id,
        NumeroTiers: commande?.clientId,
        NumeroTiersPayeur: commande?.clientId,
        Intitule: commande?.client[0]?.Intitule,
        Adresse: commande?.client[0]?.Adresse,
        Ville: commande?.client[0]?.Ville,
        CodePostal: commande?.client[0]?.CodePostal,
        Pays: commande?.client[0]?.Pays,
        Email: commande?.client[0]?.Email,
        Telephone: commande?.client[0]?.Telephone,
        Representant:
          selectedRepObject !== null
            ? `${selectedRepObject?.Prenom} ${selectedRepObject?.Nom}`
            : `${user?.firstname} ${user?.lastname}`,
        RepresentantEmail: selectedRepObject !== null ? selectedRepObject.Email : user?.mail,
        TotalCart: commande?.totalCart,
        // Statut: 0,
        // Provenance: 0,
        DateLivraison: formatDateToW100(commande?.deliveryDate),
        // DateLivraisonRealisee: formatDateToW100(),
        // DateExpedition: formatDateToW100(),
        // SectionAnalytique: "",
        // Contact: "",
        // NbFactures: 1,
        // NbColis: 1,
        TauxEscompte: commande?.client[0]?.TauxEscompte,
        // Langue: 0,
        // Donnee1: "",
        // Donnee2: "",
        // Donnee3: "",
        // Donnee4: "",
        // EstImprime: false,
        // EstTransfere: false,
        // EstCloture: false,
        // EstReliquat: false,
        IdSouche: 3,
        IdCollaborateur: selectedRepObject !== null ? selectedRepObject.Id : user?.repId,
        IdDepot: 1,
        // IdAdresseLivraison: 0,
        IdModeExpedition: commande?.client[0]?.IdModeExpedition,
        // IdConditionLivraison: 0,
        IdCategorieComptable: commande?.client[0]?.IdCategorieComptable,
        IdCategorieTarifaire: commande?.client[0]?.IdCategorieTarifaire,
        IdModeleReglement: commande?.client[0]?.IdModeleReglement,
        IdDevise: commande?.client[0]?.IdDevise,
        // CoursDevise: 0,
        // FraisExpedition: 0,
        // TypeFraisExpedition: 0,
        // TypePrixFraisExpedition: 0,
        // FrancoDePort: 0,
        // TypeFrancoPort: 0,
        // TypePrixFrancoPort: 0,
        ExpeditionCodeTaxe1: commande?.client[0]?.IdCategorieComptable === 1 ? "C04" : null,
        // ExpeditionCodeTaxe2: null,
        // ExpeditionCodeTaxe3: null,
        ExpeditionTaux1: commande?.client[0]?.IdCategorieComptable === 1 ? 20.0 : 0,
        // ExpeditionTaux2: 0,
        // ExpeditionTaux3: 0,
        InfosLibres: [{ Name: "Commentaires", Value: `${commande.commentaire}` }],
      },
      ligneList: lignesList,
      useSageProcess: false,
    };

    const response = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    });

    // console.log("response create order: ", await response.json());

    return response;
  };

  const clickValiderCommandeBtn = async () => {
    if (!user) {
      toast.error("Vous n'êtes pas authentifié !");
      return;
    }

    if (!commande || !commande.cart[0]?.product) {
      toast.error("Votre panier est vide !");
      return;
    }

    if (!commande.clientId) {
      toast.error(t("Veuillez choisir un client"));
      return;
    }

    if (!commande.client[0]?.Intitule) {
      toast.error(t("Veuillez choisir un client"));
      return;
    }

    if (commande.commentaire.length > 300) {
      toast.error(t("Veuillez ne pas dépasser 300 caractères dans le commentaire"));
      return;
    }

    const loadingToast = toast.loading("Chargement...");
    setButtonDisabled(true);

    try {
      const response = await sendCommand(commande, lignesList, accessTokenJwt);

      if (!response.ok) {
        throw new Error("Erreur lors de la création de la commande");
      }

      toast.dismiss(loadingToast);
      toast.success(t("Commande envoyée avec succès"));

      setTimeout(() => {
        navigate("/produits/famille/all");
        setCurrent("/produits");
        clearCart();
      }, 2000);
    } catch (error) {
      toast.dismiss(loadingToast);
      setButtonDisabled(false);

      if (
        error.message === "Failed to fetch" ||
        error.message === "NetworkError when attempting to fetch resource."
      ) {
        toast.error("Erreur réseau.");
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <button
      onClick={() => clickValiderCommandeBtn()}
      style={ValiderCommandeBtn}
      disabled={buttonDisabled}>
      {t("Valider la commande")} <CheckOutlined />
    </button>
  );
};

export default ValiderCommande;
